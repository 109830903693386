import { FC, ReactNode, useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AttestationType } from 'uns-common';
import { getDeviceVerificationModal } from './deviceVerification';
import './verifyDeviceActions.scss';

export const VerifyDeviceActions: FC = () => {
	const [modal, setModal] = useState<ReactNode>();

	function useDeviceVerificationModalCallback(
		attestationType: AttestationType
	): () => ReactNode {
		return useCallback(
			() =>
				getDeviceVerificationModal(attestationType, () => setModal(undefined)),
			[attestationType]
		);
	}

	const verifyDeviceUsingEmail = useDeviceVerificationModalCallback(
		AttestationType.EMAIL_VERIFIED
	);
	const verifyDeviceUsingPhone = useDeviceVerificationModalCallback(
		AttestationType.PKI
	);
	const verifyDeviceUsingWebAuthn = useDeviceVerificationModalCallback(
		AttestationType.WEBAUTHN
	);

	return (
		<div className="d-flex flex-column align-items-center">
			<strong className="mt-3">Verify Device</strong>
			<div className="w-100 d-flex flex-wrap align-items-center verify-device-actions">
				<div className="col-md-4 col-xs-12 flex-grow-1">
					<Button onClick={() => setModal(verifyDeviceUsingEmail())}>
						Using Email
					</Button>
				</div>
				<div className="col-md-4 col-xs-12 flex-grow-1">
					<Button onClick={() => setModal(verifyDeviceUsingPhone())}>
						Using Phone
					</Button>
				</div>
				<div className="col-md-4 col-xs-12 flex-grow-1">
					<Button onClick={() => setModal(verifyDeviceUsingWebAuthn())}>
						Using WebAuthn
					</Button>
				</div>
			</div>
			{modal}
		</div>
	);
};

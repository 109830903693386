import {
	startAuthentication,
	startRegistration
} from '@simplewebauthn/browser';
import { FC, useCallback } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { account } from '../../common/api';
import { useUser } from '../authenticated';
import { ModalHeader } from '../modalHeader';

export const DeviceVerificationViaWebAuthnModal: FC<{
	nonce?: string;
	closeModal: () => void;
}> = ({ nonce, closeModal }) => {
	const { refresh } = useUser();

	const biometricText = 'WebAuthn';

	const register = useCallback(async () => {
		const options = await account.initalizeWebAuthnRegistration();
		const credential = await startRegistration(options);
		await account.finalizeWebAuthnRegistration(credential, nonce);
		refresh();
	}, [nonce, refresh]);

	const login = useCallback(async () => {
		const options = await account.initalizeWebAuthnAuthentication();
		const credential = await startAuthentication(options);
		await account.finalizeWebAuthnAuthentication(credential, nonce);
		refresh();
	}, [nonce, refresh]);

	return (
		<Modal show onHide={closeModal}>
			<ModalHeader />
			<Modal.Body>
				<h6>{`Login to UNS Identity Guardian using ${biometricText}`} </h6>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={register}>Create Account</Button>
				<div>- OR -</div>
				<Button onClick={login}>Login</Button>
			</Modal.Footer>
		</Modal>
	);
};

import { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';

export const AccountDeleteModal: FC<{
	showDeleteAccount: boolean;
	closeModal?: () => void;
	confirmDelete: () => void;
	deleteError?: string;
}> = ({ showDeleteAccount, closeModal, confirmDelete, deleteError }) => {
	return (
		<Modal show={showDeleteAccount} onHide={closeModal}>
			<Modal.Header closeButton>
				<Modal.Title>Delete Account</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					Are you sure you want to delete this account? Everything connected to
					the account will be deleted and you will be logged out.
				</p>
				{deleteError && <p style={{ color: 'red' }}>{deleteError}</p>}
			</Modal.Body>
			<Modal.Footer>
				<Button type="button" onClick={confirmDelete}>
					Confirm
				</Button>
				<Button type="button" onClick={closeModal}>
					Cancel
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

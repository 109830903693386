import { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';

export const AccountDeleteDeviceModal: FC<{
	showDeleteDevice: boolean;
	closeModal?: () => void;
	confirmDelete?: () => void;
}> = ({ showDeleteDevice, closeModal, confirmDelete }) => {
	return (
		<Modal show={showDeleteDevice} onHide={closeModal}>
			<Modal.Header closeButton>
				<Modal.Title>Delete Device</Modal.Title>
			</Modal.Header>
			<Modal.Body>Are you sure you want to delete the device?</Modal.Body>
			<Modal.Footer>
				<Button type="button" onClick={confirmDelete}>
					Delete
				</Button>
				<Button type="button" onClick={closeModal}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

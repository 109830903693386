import { Environment } from '../contracts';
import { BaseLogger, MessageOrPayload } from './base-logger';

export class LogglyLogger extends BaseLogger {
	readonly url: string;

	constructor(
		private readonly environment: Environment,
		private readonly tag: string,
		token: string
	) {
		super();
		this.url = `http://logs-01.loggly.com/inputs/${token}/tag/http/`;
	}

	async log<T extends MessageOrPayload>(
		messageOrPayload: T,
		logLevel = this.defaultLogLevel
	) {
		const payload = {
			...this.format(messageOrPayload),
			logLevel
		};

		await fetch(this.url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-LOGGLY-TAG': `${logLevel},${this.tag},${this.environment}`
			},
			body: JSON.stringify(payload)
		});
	}
}

import { FC } from 'react';
import { Modal } from 'react-bootstrap';

export const QRCodeModal: FC<{
	closeModal: () => void;
	assocTicket?: string;
	error?: string;
	once?: boolean;
}> = ({ closeModal, assocTicket, error, once }) => {
	if (error) {
		return <>{error}</>;
	}
	return (
		<Modal show onHide={closeModal}>
			<Modal.Header closeButton>
				<Modal.Title>
					{once ? 'Authenticate ticket' : 'Association QR CODE'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{assocTicket && <img src={assocTicket} alt="Association Ticket"></img>}
			</Modal.Body>
		</Modal>
	);
};

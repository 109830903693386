import { BaseLogger } from './base-logger';

export * from './base-logger';
export * from './log-level';
export * from './console-logger';
export * from './loggly-logger';

export let logger: BaseLogger;

export function initLogger(log: BaseLogger): BaseLogger {
	return (logger = log);
}

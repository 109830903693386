import { FC, useCallback, useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { QRCodeModal } from './QRCodeModal';
import { ticket } from '../../common/api';
import { useAsync } from 'uns-common-react';

export const TrustDeviceModal: FC<{ nonce: string }> = ({ nonce }) => {
	const [once, setOnce] = useState(false);
	const [showQRCodeModal, setShowQRCodeModal] = useState(false);

	const toggleQRCodeModal = () => {
		setShowQRCodeModal((value) => !value);
	};

	const {
		loading,
		data,
		execute: fetchAssociationTicket,
		error
	} = useAsync(
		(nonce?: string, once?: boolean) =>
			ticket.getAssociationTicket(nonce, once),
		[nonce],
		false
	);

	const associateDevice = useCallback(async () => {
		setOnce(false);
		await fetchAssociationTicket(nonce, false);
		toggleQRCodeModal();
	}, [fetchAssociationTicket, nonce]);

	const associateDeviceOnce = useCallback(async () => {
		setOnce(true);
		await fetchAssociationTicket(nonce, true);
		toggleQRCodeModal();
	}, [fetchAssociationTicket, nonce]);

	return (
		<>
			{loading && (
				<>
					<Spinner animation="border" variant="primary" role="status" />
					<p>Loading QR Code</p>
				</>
			)}
			{showQRCodeModal && (
				<QRCodeModal
					assocTicket={data}
					closeModal={toggleQRCodeModal}
					error={error?.message}
					once={once}
				/>
			)}
			{error && <Alert variant="danger">{error.message}</Alert>}
			<h4>UNS Security Alert</h4>
			<div>
				If you choose to trust this browser, you will not be asked the next time
				you use UNS to login to a site.
			</div>
			<div className="buttons">
				<div>
					<Button variant="light" onClick={() => associateDeviceOnce()}>
						Trust Once
					</Button>
				</div>
				<div>
					<Button variant="light" onClick={() => window.close()}>
						Don't Trust
					</Button>
					<Button className="trust" onClick={() => associateDevice()}>
						Trust
					</Button>
				</div>
			</div>
		</>
	);
};

import { FC } from 'react';
import './deviceVerificationViaPhoneModal.scss';
import { QRCodeModal } from './QRCodeModal';
import { ticket } from '../../common/api';
import { useAsync } from 'uns-common-react';
import { Spinner } from 'react-bootstrap';
import { useUser } from '../authenticated';
import { AttestationType } from 'uns-common';

export const DeviceVerificationViaPhoneModal: FC<{
	nonce?: string;
	closeModal: () => void;
}> = ({ nonce, closeModal }) => {
	console.log(nonce);
	const {
		user: { currentDevice }
	} = useUser();
	const { loading, data, error } = useAsync(
		ticket.getAssociationTicket.bind(
			null,
			nonce,
			currentDevice.attestationType >= AttestationType.PINNED_WITH_PKI
		),
		[nonce, currentDevice],
		true
	);

	if (loading) {
		return (
			<>
				<Spinner animation="border" variant="primary" role="status" />
				<p>Loading QR Code</p>
			</>
		);
	}
	return (
		<QRCodeModal
			assocTicket={data}
			closeModal={closeModal}
			once={currentDevice.attestationType >= AttestationType.PINNED_WITH_PKI}
			error={error?.message}
		/>
	);
};

import { FC, PropsWithChildren } from 'react';
import { Devices } from './account/devices';
import { Approvals } from './account/approvals';

export const Home: FC<PropsWithChildren> = ({ children }) => {
	return (
		<>
			<Devices />
			<br />
			<Approvals />
			{children && <div className="mt-3">{children}</div>}
		</>
	);
};

import { LogLevel } from './log-level';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MessageOrPayload = string | Record<string, any>;

export abstract class BaseLogger {
	get defaultLogLevel() {
		return LogLevel.INFO;
	}

	debug<T extends MessageOrPayload>(messageOrPayload: T): Promise<void> {
		return this.log(messageOrPayload, LogLevel.DEBUG);
	}

	info<T extends MessageOrPayload>(messageOrPayload: T): Promise<void> {
		return this.log(messageOrPayload, LogLevel.INFO);
	}

	warn<T extends MessageOrPayload>(messageOrPayload: T): Promise<void> {
		return this.log(messageOrPayload, LogLevel.WARN);
	}

	error<T extends MessageOrPayload>(messageOrPayload: T): Promise<void> {
		return this.log(messageOrPayload, LogLevel.ERROR);
	}

	abstract log<T extends MessageOrPayload>(
		messageOrPayload: T,
		logLevel?: LogLevel
	): Promise<void>;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	format<TData extends MessageOrPayload>(data: TData): any {
		if (!data) {
			return { message: data };
		}

		switch (typeof data) {
			case 'object':
				return Object.getOwnPropertyNames(data).reduce(
					(acc, cur) => ({ ...acc, [cur]: data[cur] }),
					{}
				);
			default:
				return { message: data };
		}
	}
}

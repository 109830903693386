import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Page } from './components';
import { DeviceVerificationViaEmailConfirmation } from './components/account/deviceVerificationViaEmailConfirmation';
import { Authenticated } from './components/authenticated';
import { Home } from './components/home';
import { ProcessTicket } from './components/ticket/processTicket';
import { VerifyTicket } from './components/ticket/verifyTicket';

export const App: FC = () => {
	const emailVerificationConfirmation = (
		<Authenticated component={DeviceVerificationViaEmailConfirmation} />
	);

	return (
		<BrowserRouter>
			<Page>
				<Routes>
					<Route
						path="/processTicket/:nonce/:gatekeeperId"
						element={<Authenticated component={ProcessTicket} />}
					/>
					<Route path="/deviceVerificationViaEmailConfirmation/:verificationCode">
						<Route path=":nonce" element={emailVerificationConfirmation} />
						<Route path="" element={emailVerificationConfirmation} />
					</Route>
					<Route
						path="/tickets/:nonce/verify"
						element={<Authenticated component={VerifyTicket} />}
					/>
					<Route path="/" element={<Authenticated component={Home} />} />
					<Route path="/*" element="404 Page not found" />
				</Routes>
			</Page>
		</BrowserRouter>
	);
};

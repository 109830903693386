import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useAsync } from 'uns-common-react';
import { ticket } from '../../common/api';
import { TrustDeviceModal } from '../account/trustDeviceModal';
import { Alert } from 'react-bootstrap';

export const VerifyTicket: FC = () => {
	const { nonce } = useParams<{
		nonce: string;
	}>() as Required<{ nonce: string }>;
	const { loading, error, success } = useAsync(
		() => ticket.verifyTicket(nonce),
		[nonce],
		true
	);

	return (
		<>
			{loading && <Alert variant="light">Verifying ticket and user...</Alert>}
			{error && <TrustDeviceModal nonce={nonce} />}
			{success && <Alert variant="success">Successfully authenticated!</Alert>}
		</>
	);
};

import { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';

export const AccountLogoutModal: FC<{
	showLogoutAccount: boolean;
	closeModal?: () => void;
	confirmLogout: () => void;
}> = ({ showLogoutAccount, closeModal, confirmLogout }) => {
	return (
		<Modal show={showLogoutAccount} onHide={closeModal}>
			<Modal.Header closeButton>
				<Modal.Title>Logout</Modal.Title>
			</Modal.Header>
			<Modal.Body>You are logging out. Are you sure?</Modal.Body>
			<Modal.Footer>
				<Button type="button" onClick={confirmLogout}>
					Logout
				</Button>
				<Button type="button" onClick={closeModal}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

import { FC, PropsWithChildren, ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import { AppHeader } from './appHeader';

export const Page: FC<PropsWithChildren<{ header?: ReactNode }>> = ({
	children
}) => {
	return (
		<Container className="d-flex justify-content-center">
			<div className="bg-white p-5 col-12 col-md-10 col-xl-8">
				<AppHeader />
				<div className="w-100">{children}</div>
			</div>
		</Container>
	);
};

import { FC, PropsWithChildren } from 'react';
import { Alert, Button, ButtonProps, Spinner } from 'react-bootstrap';

export const ActionButton: FC<
	PropsWithChildren<
		ButtonProps & { loading?: boolean; error?: string; reverse?: boolean }
	>
> = ({ loading, error, reverse, children, ...rest }) => {
	let component = (
		<Button disabled={loading} {...rest}>
			{children}
		</Button>
	);

	if (loading)
		component = (
			<div
				className={`d-flex align-items-center ${
					reverse ? 'flex-row-reverse' : ''
				}`.trim()}
			>
				{component}
				<Spinner
					animation="border"
					variant="primary"
					role="status"
					className={reverse ? 'me-2' : 'ms-2'}
				>
					<span className="visually-hidden">Loading...</span>
				</Spinner>
			</div>
		);

	if (error)
		component = (
			<div
				className={`d-flex flex-column align-items-${
					reverse ? 'end' : 'start'
				}`}
			>
				{component}

				<Alert className="mt-3" variant="danger">
					{error}
				</Alert>
			</div>
		);

	return component;
};

import { useEffect, useState } from 'react';

export const useCountdown: (seconds: number) => number = (seconds) => {
	const [count, setCount] = useState(seconds);

	useEffect(() => {
		if (count > 0) setTimeout(() => setCount(count - 1), 1000);
	}, [count, setCount]);

	return count;
};

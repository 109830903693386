import { FC, useCallback, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useAsync } from 'uns-common-react';
import { isAttestationTypeMet } from 'uns-common';
import { ticket } from '../../common/api';
import { getDeviceVerificationModal } from '../account/deviceVerification';
import { useUser } from '../authenticated';
import { Home } from '../home';
import { ProcessTicketModal } from './processTicketModal';

export const ProcessTicket: FC = () => {
	const { nonce, gatekeeperId } = useParams<{
		nonce: string;
		gatekeeperId: string;
	}>() as Required<{ nonce: string; gatekeeperId: string }>;
	const { user: { currentDevice: { attestationType } } } = useUser(); // prettier-ignore
	const { loading, data, error } = useAsync(
		() => ticket.getTicket(nonce, gatekeeperId),
		[nonce, gatekeeperId],
		true
	);
	const [showModal, setShowModal] = useState(true);
	const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

	if (loading) return <Alert variant="light">Fetching ticket...</Alert>;
	if (error) return <Alert variant="danger">{error.message}</Alert>;
	if (!data) return <>No Data</>; // This should never occur

	const { domain, attestationTypeRequired } = data;
	const modal = isAttestationTypeMet(
		attestationType,
		attestationTypeRequired
	) ? (
		<ProcessTicketModal nonce={nonce} domain={domain} closeModal={closeModal} />
	) : (
		getDeviceVerificationModal(attestationTypeRequired, closeModal, nonce)
	);

	return <Home>{showModal && modal}</Home>;
};

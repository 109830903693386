import { FC } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const AppHeader: FC<{ bottom?: boolean }> = ({ bottom }) => {
	return (
		<Navbar className="pb-2 px-0 justify-content-between">
			<h3 className="m-0">UNS Identity Guardian</h3>

			<Navbar.Brand className="m-0">
				<Nav.Link as={Link} to={process.env.PUBLIC_URL + '/'}>
					<img
						alt="logo"
						src={process.env.PUBLIC_URL + '/img/new-logo.png'}
						width={bottom ? 70 : 100}
					/>
				</Nav.Link>
			</Navbar.Brand>
		</Navbar>
	);
};

import { FC, useCallback, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { AccountDeleteModal } from './accountDeleteModal';
import { account } from '../../common/api';
import { useUser } from '../authenticated';
import { AccountLogoutModal } from './accountLogoutModal';
import { useAsync } from 'uns-common-react';

export const AccountOptionsMenu: FC = () => {
	const [showDeleteDeviceModal, setShowDeleteDeviceModal] = useState(false);
	const [showLogoutDeviceModal, setShowLogoutDeviceModal] = useState(false);
	const { error, execute: deleteUNSAccount } = useAsync(
		() => account.deleteUNSAccount(),
		[],
		false
	);

	const toggleLogoutDeviceModal = () => {
		setShowLogoutDeviceModal((value) => !value);
	};

	const toggleDeleteDeviceModal = useCallback(() => {
		setShowDeleteDeviceModal((value) => !value);
	}, []);

	const { refresh } = useUser();
	const logout = useCallback(async () => {
		await account.logout();
		refresh();
	}, [refresh]);

	const deleteAccount = useCallback(async () => {
		await deleteUNSAccount();
		toggleDeleteDeviceModal();
		refresh();
	}, [deleteUNSAccount, refresh, toggleDeleteDeviceModal]);

	return (
		<>
			<AccountDeleteModal
				showDeleteAccount={showDeleteDeviceModal}
				confirmDelete={deleteAccount}
				closeModal={() => toggleDeleteDeviceModal()}
				deleteError={error?.message}
			/>
			<AccountLogoutModal
				showLogoutAccount={showLogoutDeviceModal}
				confirmLogout={logout}
				closeModal={() => toggleLogoutDeviceModal()}
			/>
			<Dropdown>
				<Dropdown.Toggle size="sm" variant="outline-primary">
					My Account
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item>
						<Button variant="link" onClick={() => toggleLogoutDeviceModal()}>
							Logout
						</Button>
					</Dropdown.Item>
					<Dropdown.Item>
						<Button variant="link" onClick={() => toggleDeleteDeviceModal()}>
							Delete UNS account
						</Button>
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</>
	);
};

import { FC, useCallback, useState } from 'react';
import { AttestationType, isAttestationTypeMet } from 'uns-common';
import { AccountOptionsMenu } from './accountOptionsMenu';
import { useUser } from '../authenticated';
import { Device } from './device';
import { VerifyDeviceActions } from './verifyDeviceActions';
import { account } from '../../common/api';

export const Devices: FC = () => {
	const {
		user: { currentDevice, devices }
	} = useUser();
	const isAtLeastEmailVerified = isAttestationTypeMet(
		currentDevice.attestationType,
		AttestationType.EMAIL_VERIFIED
	);

	const [allDevices, setAllDevices] = useState(devices);

	const removeDeviceToggle = useCallback(
		async (id: string) => {
			await account.deleteDevice(id);
			setAllDevices((val) => val.filter((device) => device.id !== id));
		},
		[setAllDevices]
	);

	return (
		<>
			<div className="d-flex justify-content-between align-items-center pb-2">
				<h5 className="m-0">My Trusted Devices</h5>
				{isAtLeastEmailVerified && <AccountOptionsMenu />}
			</div>
			<div className="p-3 bg-light border rounded">
				<Device current device={currentDevice} />
				<VerifyDeviceActions />
			</div>
			{allDevices.length > 0 && (
				<div className="p-3 bg-light border rounded mt-2">
					{allDevices.map((device, i) => (
						<Device
							device={device}
							key={device.id}
							className={i > 0 ? 'mt-2' : undefined}
							removeDevice={removeDeviceToggle.bind(null, device.id)}
						/>
					))}
				</div>
			)}
		</>
	);
};

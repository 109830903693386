import ReactDOM from 'react-dom/client';
import {
	Environment,
	initLogger,
	ConsoleLogger,
	LogglyLogger
} from 'uns-common';
import { ENVIRONMENT, IS_DEVELOPMENT } from 'uns-common-react';
import { App } from './app';
import './index.scss';
import reportWebVitals from './reportWebVitals';

const logger =
	ENVIRONMENT === Environment.development
		? initLogger(new ConsoleLogger('guardian-ui'))
		: initLogger(
				new LogglyLogger(
					ENVIRONMENT,
					'guardian-ui',
					'024bde2a-4ec8-49ae-bf67-403968fb5e4f'
				)
			);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(IS_DEVELOPMENT ? (x) => logger.debug(x) : undefined);

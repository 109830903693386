export const GUARDIAN_ID = 'guardian-UNS';
export const GATEKEEPER_ID = 'gatekeeper-UNS';
export const TICKET_DURATION = 300; // 5min

export enum Environment {
	development = 'development',
	staging = 'staging',
	production = 'production'
}

type Entity = {
	domain: string;
	publicKey: string;
};

export type Guardian = Entity;
export type Gatekeeper = Entity;

export type ServiceIdentifier = {
	serviceId: string;
};

export type ContactInfo = {
	contactName: string;
	email: string;
	phoneNumber: string;
};

export type ContactInfoVerification = {
	emailVerificationCode: string;
	emailVerified: boolean;
};

export type ServiceInfo = {
	domain: string;
	callbackPath: string;
	validationPath: string;
};

export type ServiceInfoVerification = {
	validationCode: string;
	domainVerified: boolean;
};

export type ServiceCredentials = {
	publicKey: string;
};

export type Service = ServiceIdentifier &
	ContactInfo &
	ContactInfoVerification &
	Partial<ServiceInfo & ServiceInfoVerification & ServiceCredentials>;

export enum AttestationType {
	NONE = 'none',
	EMAIL_NOT_VERIFIED = 'email_not_verified',
	EMAIL_VERIFIED = 'email_verified',
	PINNED_WITH_PKI = 'pinned_with_pki',
	WEBAUTHN = 'webauthn',
	PKI = 'pki'
}

export const AttestationTypeDescription: Record<AttestationType, string> = {
	[AttestationType.NONE]: 'Fingerprint',
	[AttestationType.EMAIL_NOT_VERIFIED]: 'Unverified email',
	[AttestationType.EMAIL_VERIFIED]: 'Verified email',
	[AttestationType.PINNED_WITH_PKI]: 'PKI endorsed',
	[AttestationType.PKI]: 'PKI verified',
	[AttestationType.WEBAUTHN]: 'PKI verified'
};

export const AttestationTypeOrder: Record<AttestationType, number> = {
	[AttestationType.NONE]: 0,
	[AttestationType.EMAIL_NOT_VERIFIED]: 1,
	[AttestationType.EMAIL_VERIFIED]: 2,
	[AttestationType.PINNED_WITH_PKI]: 3,
	[AttestationType.WEBAUTHN]: 4,
	[AttestationType.PKI]: 4
};

export function isAttestationTypeMet(
	attestationType: AttestationType,
	attestationTypeRequired: AttestationType
): boolean {
	return (
		AttestationTypeOrder[attestationType] >=
		AttestationTypeOrder[attestationTypeRequired]
	);
}

export type AuthTicketIdenfifier = {
	nonce: string;
};

export type AuthTicket = {
	status: 'CREATED' | 'CLAIMED' | 'AUTHORIZED';
	serviceId: string;
	sessionId: string;
	attestationTypeRequired: AttestationType;
	guardianUrl: string;
	gatekeeperUrl: string;
	qrCodeUrl?: string;
	serviceUserId?: string;
	nonce?: string;
};

export type AssociationTicket = {
	associationID: string;
	createdBy: string;
	createdAt: Date;
	associatedBy?: string;
	associatedAt?: Date;
	nonce?: string;
};

export type GuardianAuthTicket = AuthTicketIdenfifier & {
	status: string;
	serviceId: string;
	gatekeeperId: string;
	domain: string;
	attestationTypeRequired: AttestationType;
	scanningDeviceId?: string;
	scanningUserId?: string;
	allowedDeviceId?: string;
	authTime?: Date;
};

export type User = {
	id: string;
	devicesIds: string[];
	authenticators: {
		webAuthn: { [key: string]: Authenticator };
		unsAuthenticators: { [deviceId: string]: string };
	};
	currentDevice?: Device;
	devices?: Device[];
};

export type UserSummary = Omit<User, 'devicesIds' | 'authenticators'>;

export type Device = {
	id: string;
	userId: string;
	attestationType: AttestationType;
	createdAt: Date;
	isDeleted: boolean;
	deviceType: string;
	deviceBrand: string;
	deviceName: string;
	browserName: string;
	browserVersion: string;
	osName: string;
	osVersion: string;
	OS?: string;
	challenge?: string;
};

export type Authenticator = {
	credentialID: Buffer;
	credentialPublicKey: Buffer;
	counter: number;
};

export type QRCodeValue = {
	guardianURL: string;
	authNonce?: string;
	associationNonce?: string;
	gatekeeperId?: string;
	once?: boolean;
};

export type TicketNonceByService = {
	[domain: string]: string[];
};

export type GuardianAuthTicketByService = {
	[domain: string]: GuardianAuthTicket[];
};

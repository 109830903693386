import { DetailedHTMLProps, FC, HTMLAttributes, useEffect } from 'react';
import { useCountdown } from '../hooks/useCountdown';

export const CountdownMessage: FC<
	DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
		format: string;
		start: number;
		endAction?: () => void;
	}
> = ({ format, start, endAction, ...rest }) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const formatString = function (s: string, ...rest: any[]) {
		let i = rest.length;
		while (i--) s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), rest[i]);
		return s;
	};

	const count = useCountdown(start);

	useEffect(() => {
		if (endAction) setTimeout(endAction, start * 1000);
	}, [endAction, start]);

	return <div {...rest}>{formatString(format, count)}</div>;
};

import { useAsync } from 'uns-common-react';
import { ticket } from '../../common/api';
import { DomainApprovals } from './domainApprovals';

export const Approvals: React.FC = () => {
	const { loading, error, data } = useAsync(ticket.getUserTickets, [], true);
	if (loading) return <>Loading approvals...</>;
	if (error) return <></>;
	if (data) {
		const domains = Object.keys(data);
		if (domains.length > 0)
			return (
				<>
					<h5> My approvals </h5>
					<div className="p-3 bg-light border rounded mt-2">
						{domains.map((domain) => (
							<DomainApprovals
								domain={domain}
								tickets={data[domain]}
								key={domain}
							/>
						))}
					</div>
				</>
			);
	}
	return <></>;
};

import { FC } from 'react';
import { Modal } from 'react-bootstrap';

export const ModalHeader: FC = () => {
	return (
		<Modal.Header closeButton>
			<div className="text-primary d-flex align-items-center">
				<img alt="logo" src="/img/new-logo.png" width="30" />
				<strong className="ms-1">UNS</strong>&nbsp;Identity Guardian
			</div>
		</Modal.Header>
	);
};

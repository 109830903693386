import { ReactNode } from 'react';
import { AttestationType } from 'uns-common';
import { DeviceVerificationViaEmailModal } from './deviceVerificationViaEmailModal';
import { DeviceVerificationViaPhoneModal } from './deviceVerificationViaPhoneModal';
import { DeviceVerificationViaWebAuthnModal } from './deviceVerificationViaWebAuthnModal';

export function getDeviceVerificationModal(
	attestationTypeRequired: AttestationType,
	closeModal: () => void,
	nonce?: string
): ReactNode {
	switch (attestationTypeRequired) {
		case AttestationType.EMAIL_VERIFIED:
			return (
				<DeviceVerificationViaEmailModal
					nonce={nonce}
					closeModal={closeModal}
				/>
			);

		case AttestationType.PINNED_WITH_PKI:
		case AttestationType.PKI:
			return (
				<DeviceVerificationViaPhoneModal
					nonce={nonce}
					closeModal={closeModal}
				/>
			);

		case AttestationType.WEBAUTHN:
			return (
				<DeviceVerificationViaWebAuthnModal
					nonce={nonce}
					closeModal={closeModal}
				/>
			);

		default:
			throw new Error(`No proper modal found for ${attestationTypeRequired}.`);
	}
}

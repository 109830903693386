import { FC, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useAsync } from 'uns-common-react';
import { account } from '../../common/api';
import { useUser } from '../authenticated';
import { Home } from '../home';

export const DeviceVerificationViaEmailConfirmation: FC = () => {
	const { verificationCode, /*hash, */ nonce } = useParams<{
		verificationCode: string;
		hash: string;
		nonce?: string;
	}>() as { verificationCode: string; hash: string; nonce?: string };
	const { loading, error, success } = useAsync(
		() => account.verifyEmail(verificationCode, nonce),
		[verificationCode, nonce],
		true
	);
	const { refresh } = useUser();
	const navigate = useNavigate();

	useEffect(() => {
		if (loading === false) {
			setTimeout(() => navigate('/', { replace: true }), 1500);
			refresh();
		}
	}, [loading, refresh, navigate]);

	return (
		<Home>
			{loading && <Alert variant="light">Confirming email...</Alert>}
			{error && <Alert variant="danger">{error.message}</Alert>}
			{success && <Alert variant="success">Email verified successfully.</Alert>}
		</Home>
	);
};
